import { C } from "../common/component";
import { Dom } from "../common/dom";
import { FormRowType } from "./form-row-type";
import { Helper } from "../common/helper";
import { Requester } from "../common/requester";
import { FormRowModel, MatchValue } from "./form";
import { EditControlBuilder } from "./edit-control-builder";
import { FormComponent } from "./form-component";

export interface EditControl {
    initialize(parent: HTMLElement): void;
    setValue(value: string, additionalInfo?): Promise<void>;
    getValue(): string;
    onChange(action: () => void): void;
    validate?(): boolean;
    focus(): void;
}

export class FormRow {
    private readonly changeActions: (() => void)[] = [];
    private readonly editControl: EditControl;
    private readonly form: FormComponent;
    private readonly titleLabel: HTMLLabelElement;
    private readonly rowControlPanel: HTMLSpanElement;
    private readonly row: HTMLDivElement;
    private readonly info: FormRowModel;

    private isHidden = false;

    constructor(parent: JQuery, info: FormRowModel, form: FormComponent, isCompact: boolean) {
        this.info = info;
        this.form = form;
        this.row = Dom.addDiv(parent[0], "form-group");
        this.titleLabel = Dom.add("label", this.row, "control-label");
        this.titleLabel.innerText = info.MainData.DisplayName;
        if (info.MainData.IsRequired && info.TypeData.Type !== FormRowType.Boolean) {
            const requiredElement = Dom.addSpan(this.titleLabel);
            requiredElement.innerText = "*";
            requiredElement.style.color = "red";
        }
        if (info.MainData.Tooltip) {
            C.img.addClass("help-icon")
                .attr("src", "images/help.png")
                .attr("data-toggle", "tooltip")
                .attr("title", info.MainData.Tooltip)
                .appendTo(this.row)
                .tooltip();
        }
        this.rowControlPanel = Dom.createSpan();
        if (info.TypeData.Type !== FormRowType.Boolean) {
            this.row.append(this.rowControlPanel);
        } else {
            if (isCompact) {
                this.titleLabel.style.marginRight = "5px";
            }
            this.row.prepend(this.rowControlPanel);
        }
        if (info.MainData.Description) {
            const description = Dom.addDiv(this.row, "form-text", "text-muted");
            description.style.marginLeft = "25px";
            description.innerHTML = Helper.removeScriptTags(info.MainData.Description);
        }

        this.changeActions = [];
        this.editControl = this.getEditControl(info, isCompact);
        this.setVisibility(Object.keys(info.DisplayIf).length === 0 && !info.ShouldDisplayUrl);
    }

    async initializeOtherRows(rows: { [name: string]: FormRow }) {
        const usedFields = [];
        for (const key of Object.keys(this.info.DisplayIf)) {
            usedFields.push(key);
        }
        if (this.info.ShouldDisplayUrl) {
            for (const parameter of this.info.ShouldDisplayUrl.Parameters) {
                usedFields.push(parameter.RowName);
            }
        }
        if (Object.keys(usedFields).length > 0) {
            await this.askAndSetVisibility();
            for (const field of usedFields) {
                rows[field].onChange(async () => await this.askAndSetVisibility());
            }
        }
    }

    private async askAndSetVisibility() {
        let isDisplayed = true;
        for (const rowName in this.info.DisplayIf) {
            if (!FormRow.doesMatch(this.info.DisplayIf[rowName], this.form.getRowValue(rowName))) {
                isDisplayed = false;
                break;
            }
        }
        if (isDisplayed && this.info.ShouldDisplayUrl) {
            const data = {};
            for (const parameter of this.info.ShouldDisplayUrl.Parameters) {
                data[parameter.Name] = this.form.getRowValue(parameter.RowName);
            }
            isDisplayed = await Requester.get(Requester.getUrl(this.info.ShouldDisplayUrl.Url, data));
        }
        this.setVisibility(isDisplayed);
    }

    private static doesMatch(matchValue: MatchValue, value: string): boolean {
        if (matchValue.IsSubstring) {
            for (const x of matchValue.Values) {
                if ((value ?? "").includes(x)) {
                    return true;
                }
            }
            return false;
        }
        return matchValue.Values.includes(value) === !matchValue.IsNotIn;
    }

    async setValue(value: string, additionalInfo): Promise<void> {
        await this.editControl.setValue(value, additionalInfo);
    }

    getValue(): string {
        if (this.isHidden || this.editControl.validate && !this.editControl.validate()) {
            return null;
        }
        return this.editControl.getValue();
    }

    onChange(action: () => void) {
        this.changeActions.push(action);
    }

    validate(): boolean {
        if (this.isHidden) {
            return true;
        }
        let result = !this.editControl.validate || this.editControl.validate();
        if (result && this.info.MainData.IsRequired) {
            const value = this.editControl.getValue();
            if (value === null || value === JSON.stringify([])) {
                result = false;
            }
        }
        if (!result) {
            this.titleLabel.style.color = "#b94a48";
            if (this.editControl.focus) {
                this.editControl.focus();
            }
        }
        return result;
    }

    private getEditControl(infoToUse: FormRowModel, isCompact: boolean): EditControl {
        const control = EditControlBuilder.create(infoToUse.TypeData, infoToUse.MainData.Unit, rowName => this.form.getRowValue(rowName),
            null, isCompact, infoToUse.MainData.IsRequired, () => this.form.triggerMainButton());
        control.initialize(this.rowControlPanel);
        control.onChange(() => {
            this.titleLabel.style.color = "";
            for (const action of this.changeActions) {
                action();
            }
        });
        return control;
    }

    private setVisibility(isDisplayed: boolean) {
        if (isDisplayed) {
            this.row.style.display = "";
            this.isHidden = false;
        } else {
            this.row.style.display = "none";
            this.isHidden = true;
        }
    }
}
