import { TimesheetData, TimesheetUpdateData } from "./timesheet-models";
import { TimesheetLeftTable } from "./timesheet-left-table";
import { TimesheetRightTable } from "./timesheet-right-table";
import { TimesheetRightTableDataManager, TimesheetTableData } from "./timesheet-right-table-data-manager";
import { TimesheetShortcutsSet } from "./timesheet-shortcuts-set";
import { EditableTableCalculationsData, EditableTableCalculator } from "../common/editable-table";
import { TimesheetCommentsManager } from "./timesheet-comments-manager";
import { Requester } from "../common/requester";

export class Timesheet {
    private readonly calculator = new EditableTableCalculator(true);
    private leftTable: TimesheetLeftTable;
    private rightTable: TimesheetRightTable;
    private rightTableDataManager: TimesheetRightTableDataManager;

    private rightTableRowNumbersByRowId: { [id: number]: number[] } = {};
    private data: TimesheetData;

    private readonly parent;
    private readonly shortcuts: TimesheetShortcutsSet;
    private readonly width: number;

    constructor(parent, shortcuts: TimesheetShortcutsSet, width: number) {
        this.parent = parent;
        this.shortcuts = shortcuts;
        this.width = width;
    }

    setData(data: TimesheetData) {
        this.data = data;
        this.leftTable = new TimesheetLeftTable(data.LeftTable, this.width, data.IsCompact, this.calculator);
        setTimeout(async () => {
            const rowsByEmployee = new Map();
            for (const rowId in data.RightTableRowsData) {
                const row = data.RightTableRowsData[rowId];
                if (!rowsByEmployee.has(row.EmployeeId)) {
                    rowsByEmployee.set(row.EmployeeId, [rowId]);
                } else {
                    rowsByEmployee.get(row.EmployeeId).push(rowId);
                }
            }
            const groupedRows = [];
            rowsByEmployee.forEach((rowIds, _) => groupedRows.push(rowIds));
            for (const rows of groupedRows) {
                const calculationsData: EditableTableCalculationsData[] = await Requester.post(data.CalculationsDataUrl, rows);
                this.setCalculationsData(calculationsData);
                this.rightTable.setHeaderHeight(this.leftTable.getHeaderHeight());
            }
            // This is needed to update calculated header row
            this.rightTable.render();
        }, 100);
        this.leftTable.appendTo(this.parent);

        let rowNumber = 0;
        for (const rowId of data.RightTableRows) {
            if (!this.rightTableRowNumbersByRowId[rowId]) {
                this.rightTableRowNumbersByRowId[rowId] = [];
            }
            this.rightTableRowNumbersByRowId[rowId].push(rowNumber);
            rowNumber++;
        }

        const rightTableData = new TimesheetTableData(data.SaveUrl, data.DateDataUrl);
        for (const row of data.RightTableRows) {
            rightTableData.addRow(data.RightTableRowsData[row]);
        }

        const commentsManager = new TimesheetCommentsManager(data.RightTableColumns, data.RightTableRows, data.RightTableComments,
            data.CommentSaveUrl, data.RightTableRowsWithEditableComments);
        this.rightTableDataManager = new TimesheetRightTableDataManager(data.RightTableColumns, data.RightTableRows, this.shortcuts,
            rightTableData, (updateData: TimesheetUpdateData) => this.updateData(updateData));

        this.rightTable = new TimesheetRightTable(data.RightTableColumns, data.RightTableBorderRows, data.RowHeight, data.RowHeaderWidth,
            this.width, data.MultiCellButtons, data.IsCompact);
        this.rightTable.appendTo(this.parent, this.rightTableDataManager, commentsManager, this.calculator);
        this.rightTable.setHeaderHeight(this.leftTable.getHeaderHeight());
    }

    private updateData(updateData: TimesheetUpdateData) {
        this.setCalculationsData(updateData.CalculationsData);
        // Right table header calculations need right table render to be updated which is done when setDataAtCellsWithoutSave is called

        let startColumn = 0;
        for (const column of this.data.RightTableColumns) {
            if (column.Date === updateData.DisplayDataStartDate) {
                break;
            }
            startColumn++;
        }
        const changes: any[][] = [];
        for (const rowId in updateData.DisplayData) {
            const rowData = updateData.DisplayData[rowId];
            for (const rowNumber of this.rightTableRowNumbersByRowId[rowId]) {
                for (let i = 0; i < rowData.length; i++) {
                    this.rightTableDataManager.setCellData(rowNumber, startColumn + i, rowData[i]);
                    changes.push([rowNumber, startColumn + i, rowData[i].Value]);
                }
            }
        }
        this.rightTable.setDataAtCellsWithoutSave(changes);
    }

    private setCalculationsData(data: EditableTableCalculationsData[]) {
        for (const x of data) {
            this.calculator.setCalculationValue(x);
        }
        this.calculator.refreshCalculations();
    }
}
